import axios from 'axios'

const API = axios.create({ baseURL: 'https://portfolio-eril.herokuapp.com' })

API.interceptors.request.use((req) => {
    if(localStorage.getItem('profile')) {
        req.headers.Authorization = `Bearer ${JSON.parse(localStorage.getItem('profile'))?.token}`;
    }
    return req;
});

export const fetchProjects = () => API.get('/project');
export const fetchMiniProjects = () => API.get('/project/mini');
export const fetchFullProject = (id) => API.get(`/project/full/${id}`);
export const fetchDetailProject = (id) => API.get(`/project/detail/${id}`);
export const createProject = (newProject) => API.post('/project', newProject);
export const updateProject = (id, updatedProject) => API.patch(`/project/${id}`, updatedProject);
export const deleteProject = (id) => API.delete(`/project/${id}`);

export const signIn = (formData) => API.post('/user/signin', formData);
export const signUp = (formData) => API.post('/user/signup', formData);