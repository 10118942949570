import React from 'react';

import { CircularProgress, Container } from '@material-ui/core'

const DefaultLoad = () => {

    return (
        <Container style={{display: 'flex', justifyContent: 'center', height: '90vh'}} >
            <CircularProgress style={{alignSelf: 'center'}} />
        </Container>
    )
};

export default DefaultLoad;