import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  container: {
    marginTop: '3rem',
    flexGrow: '1',
    //border: '1px solid lightgrey',
    borderRadius: '1rem',
    boxShadow: '0 1px 5px lightgray',
    padding: '1rem',
    width: '100%',
    overflowX: 'auto',
    wordWrap: 'break-word'
  },
  content: {
    marginTop: '2rem',
    fontFamily: 'arial',
    lineHeight: '1.5rem',
  },
  tag: {
    height: '2.5rem',
    margin: '.5rem 0 .2rem 0',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100% 100%',
    display: 'inline-block',
  },
  menuHolder: {
    right: '0',
    position: 'absolute',
    margin: '1rem 2rem'
  },
  smallContHold: {
    flexGrow: '1',
    display: 'flex',
    height: 'fit-content',
  },
  smallCont: {
    borderRadius: '1rem',
    padding: '0 1rem',
    margin: '3rem 0 3rem 0.5rem',
    maxWidth: '500px',
  },
  [theme.breakpoints.down('sm')]: {
    smallCont: {
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  },
  linkItem: {
    borderRadius: '2rem',
    margin: '0.5rem 0'
  }
}));