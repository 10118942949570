import { FETCH_ALL, FETCH_FULL, CREATE, UPDATE, DELETE } from '../constants/actionTypes'
import * as api from '../api';


// Action Creators

export const getProjects = () => async (dispatch) => {

    try {
        const { data } = await api.fetchProjects();
        dispatch({ type: FETCH_ALL, payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

export const getMiniProject = () => async (dispatch) => {

    try {
        const { data } = await api.fetchMiniProjects();
        dispatch({ type: FETCH_ALL, payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

export const getProjectDetails = (id) => async (dispatch) => {

    try {
        const { data } = await api.fetchDetailProject(id);
        dispatch({ type: FETCH_FULL, payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

export const getFullProject = (id) => async (dispatch) => {

    try {
        const { data } = await api.fetchFullProject(id);
        dispatch({ type: FETCH_FULL, payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

export const createProject = (project) => async (dispatch) => {
    try {
        const { data } = await api.createProject(project);
        dispatch({ type: CREATE, payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

export const updateProject = (id, project) => async (dispatch) => {
    try {
        const { data } = await api.updateProject(id, project);

        dispatch({ type: UPDATE, payload: data });
    } catch (error) {
        console.log(error.message);
    }
}

export const deleteProject = (id) => async (dispatch) => {
    try {
        await api.deleteProject(id);

        dispatch({ type: DELETE, payload: id });
    } catch (error) {
        console.log(error.message);
    }
}