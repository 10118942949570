import { Tooltip } from "@material-ui/core";

const badges = [
    {id: 1, name: "C++"             , title: "C++ Programming language", source: "https://img.icons8.com/color/48/000000/c-plus-plus-logo.png"},
    {id: 13, name: "C#"             , title: "C# Programming language", source: "https://img.icons8.com/color/48/000000/c-sharp-logo.png"},
    {id: 14, name: "OpenGL"         , title: "OpenGL", source: "https://www.khronos.org/assets/images/api_logos/opengl.svg"},
    {id: 18, name: "DirectX"        , title: "DirectX 11/12", source: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7f/Microsoft-DirectX-Logo-wordmark.svg/1920px-Microsoft-DirectX-Logo-wordmark.svg.png"},
    {id: 12, name: "Elixir"         , title: "Elixir Programming language", source: "https://elixir-lang.org/images/logo/logo.png"},
    {id: 2,  name: "NodeJS"         , title: "Node JS (JavaScript) Programming language", source: "https://img.icons8.com/color/48/000000/nodejs.png"},
    {id: 3,  name: "ReactJS"        , title: "React JS", source: "https://img.icons8.com/plasticine/100/000000/react.png"},
    {id: 4,  name: "Python"         , title: "Python Programming language", source: "https://img.icons8.com/fluency/96/000000/python.png"},
    {id: 5,  name: "HTML"           , title: "HTML 5", source: "https://img.icons8.com/external-tal-revivo-color-tal-revivo/96/000000/external-html-5-is-a-software-solution-stack-that-defines-the-properties-and-behaviors-of-web-page-logo-color-tal-revivo.png"},
    {id: 6,  name: "SDL"            , title: "SDL Game Framework", source: "https://www.libsdl.org/media/SDL_logo.png"},
    {id: 7,  name: "Unreal Engine"  , title: "Unreal Engine 4/5", source: "https://img.icons8.com/ios-filled/50/000000/unreal-engine.png"},
    {id: 8,  name: "Unity"          , title: "Unity Game Engine", source: "https://img.icons8.com/ios-filled/50/000000/unity.png"},
    {id: 15, name: "Godot"          , title: "Godot Game Engine", source: "https://godotengine.org/assets/press/icon_color.svg"},
    {id: 9,  name: "AWS"            , title: "Amazon Web Services", source: "https://img.icons8.com/color/48/000000/amazon-web-services.png"},
    {id: 10, name: "Android"        , title: "Android OS", source: "https://img.icons8.com/color/48/000000/android-os.png"},
    {id: 11, name: "iOS"            , title: "Apple iOS", source: "https://img.icons8.com/color/48/000000/ios-logo.png"},
    {id: 16, name: "Blender"        , title: "Blender", source: "https://img.icons8.com/color/96/000000/blender-3d.png"},
    {id: 17, name: "Steam"          , title: "Steam", source: "https://img.icons8.com/fluency/96/000000/steam.png"},
    {id: 19, name: "Houdini"        , title: "Houdini", source: "https://upload.wikimedia.org/wikipedia/commons/1/15/Houdini3D_icon.png"},
]

export const makeBadge = (id, tag) => {
    var b = badges.find((i) => i.id===id);
    return (<Tooltip title={b.title} arrow><img src={b.source} className={tag} alt={b.name}/></Tooltip>)
}

export const getTags = () => {
    return badges
}

export const getName = (id) => {
    return badges.find((i) => i.id === id).name;
}

export const getNameId = (id) => {
    return badges.find((i) => i.name === id).id;
}