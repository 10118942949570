import { FETCH_ALL, CREATE, UPDATE, DELETE } from '../constants/actionTypes'

const sortObjects = (a, b) => {
    if (a?.priority === b?.priority) return 0;
    return a?.priority < b?.priority ? 1 : -1;
}

const project_reducer = (projects = [], action) => {
    switch (action.type) {
        case FETCH_ALL:
            return action.payload.sort((first, second) => { return sortObjects(first, second); });
        case CREATE:
            return [ ...projects, action.payload ].sort((first, second) => { return sortObjects(first, second); });
        case UPDATE:
            return projects.map((post) => post._id === action.payload._id ? action.payload : post).sort((first, second) => { return sortObjects(first, second); });
        case DELETE:
            return projects.filter((post) => post._id !== action.payload);
        default:
            return projects;
    }
}

export default project_reducer;