import { FETCH_FULL } from '../constants/actionTypes'

const single_reducer = (project = {}, action) => {
    switch (action.type) {
        case FETCH_FULL:
            return action.payload;
        default:
            return project;
    }
}

export default single_reducer;