import React, { useEffect, useState } from 'react';
import { Container, Grid, Button, Typography, Divider, Card, TextField} from '@material-ui/core';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { useDispatch, useSelector } from 'react-redux';
import { getProjectDetails, updateProject } from '../../actions/projects'

import EditIcon from '@material-ui/icons/Edit';
import useStyles from './styles.js';
import { makeBadge } from '../../images/badges.js';
import { useParams } from 'react-router';
import DefaultLoad from '../Loading/DefaultLoad';

import { PrismLight as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism';
import cpp from 'react-syntax-highlighter/dist/esm/languages/prism/cpp';

SyntaxHighlighter.registerLanguage('cpp', cpp);

const CodeBlock = ({node, inline, className, children, ...props}) => {
    console.log(className);
    const match = /language-(\w+)/.exec(className || '')
    return !inline && match ? (
        <SyntaxHighlighter language={match[1]} style={vscDarkPlus} {...props} children={String(children).replace(/\n$/, '')} PreTag="div" />
    ) : (
        <code {...props} className={className}>
            {children}
        </code>
    );
}

const ProjectPage = ({ inID }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const project = useSelector((state) => state.project);
    let { id } = useParams();
    const usedID = inID ? inID : id;
    const [editing, setEditing] = useState(false)
    const user = JSON.parse(localStorage.getItem('profile'));
    const [projectData, setProjectData] = useState(project)

    useEffect(() => {
        dispatch(getProjectDetails(usedID));
    }, [editing])

    const changeEdit = () => {
        if (editing) {
            dispatch(updateProject(project._id, { ...projectData, creator: user?.result?.creator }));
            setEditing(false);
        } else {
            setProjectData(project);
            setEditing(true);
        }
    }
    const style = inID ? {} : {overflowY: 'auto'};
    return (
        <Container style={style} >
            {user ? (
                <div style={{position: 'absolute', right: '2rem', marginTop: '1rem'}}>
                    <Button onClick={changeEdit}>
                        <EditIcon color="primary" />
                    </Button>
                </div>
            ) : (<></>)}
            {usedID === project._id ? (
                <Grid container>
                    <Grid item md={9} className={classes.container}>
                        <Typography variant="h2" style={{marginBottom: '1rem'}}>{project.title}</Typography>
                        <Divider/>
                        <Grid container spacing={2}>
                        {project.tags ? project.tags.map((tag) => (
                            <Grid item key={tag}>
                                {makeBadge(tag, classes.tag)}
                            </Grid>
                        )) : (<></>)}
                        </Grid>
                        <Divider/>
                        {user && editing ? (
                            <TextField 
                                multiline 
                                variant="outlined" 
                                value={projectData.content} 
                                style={{width: '100%'}} 
                                inputProps={{style: {resize: "both"}}}
                                onChange={(e) => setProjectData({ ...projectData, content: e.target.value })}
                            />
                        ) : (
                            <ReactMarkdown className={classes.content} rehypePlugins={[rehypeRaw]} components={{ code: CodeBlock }} >{project.content}</ReactMarkdown>
                        )}
                    </Grid>
                    <Grid item container md={3} className={classes.smallContHold}>
                        <Card className={classes.smallCont}>
                            <Typography className={classes.linkItem} align="center" variant="h5">
                                Links
                            </Typography>
                            <Button className={classes.linkItem} variant="contained" color="primary" fullWidth disabled={project.download ? false : true} href={project.download} target="_blank">View / Download</Button>
                            <Button className={classes.linkItem} variant="contained" color="primary" fullWidth disabled={project.gitLink ? false : true} href={project.gitLink} target="_blank">Github</Button>
                            {/* <Typography className={classes.linkItem} align="center" variant="subtitle2">
                                Licence information
                            </Typography>
                            <Typography className={classes.linkItem} align="center" variant="subtitle2">
                                {project.licence}
                            </Typography> */}
                            <div style={{paddingBottom: "2rem"}}></div>
                        </Card>
                    </Grid>
                </Grid>
            ) : (
                <DefaultLoad />
            )}
        </Container>
    );
}

export default ProjectPage;