import { Button, Dialog, Hidden, Slide, Tooltip } from '@material-ui/core';
import React, { Suspense, lazy, useState } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import DefaultLoad from './components/Loading/DefaultLoad'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import LinkIcon from '@material-ui/icons/Link'
import './globalStyle.css';
import useStyles from './styles.js';


import Project from './components/ProjectPage/ProjectPage';

const Navbar = lazy(() => import('./components/Navbar/Navbar'));
const Home = lazy(() => import('./components/Home/Home'));
const Auth = lazy(() => import('./components/Auth/Auth'));
const All = lazy(() => import('./components/Allprojects/Allprojects'));
//const Project = lazy(() => import('./components/ProjectPage/ProjectPage'));
const Form = lazy(() => import('./components/Form/Form'));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="left" ref={ref} {...props} />;
});

const App = () => {
    const user = JSON.parse(localStorage.getItem('profile'));
    const [currentId, setCurrentId] = useState(null);
    const [detail, setDetail] = useState(null);
    const [tooltip, setTooltip] = useState(false);
    const [create, setCreate] = useState(false);
    const classes = useStyles();
    const history = useHistory();

    const handleClose = () => {
        history.goBack();
        setDetail(null);
    }
    
    const handleCopy = () => {
        navigator.clipboard.writeText(`${window.location.origin}/#/project/${detail}`)
        setTooltip(true);
    }

    return (
        <Suspense fallback={<DefaultLoad />}>
            <div className={classes.container}>
            <Navbar setCreate={setCreate} />
            <Switch>
                <Route path="/auth" exact component={Auth}/>
                <Route path="/all" exact render={(props) => <All {...props} setCurrentId={setCurrentId} currentId={currentId} detail={detail} setDetail={setDetail} />}/>
                <Route path="/project/:id" exact component={Project} />
                <Route path="/" render={(props) => <Home {...props} currentId={currentId} setCurrentId={setCurrentId} detail={detail} setDetail={setDetail} />}/>
            </Switch>
            </div>
            { user ? (<Form currentId={currentId} setCurrentId={setCurrentId} create={create} setCreate={setCreate}/>) : (<></>)}
            <Dialog fullScreen open={detail!=null} onClose={handleClose} TransitionComponent={Transition}>
                <Project className={classes.frame} inID={detail} />
                <div className={classes.returnButton}>
                    <Hidden lgDown implementation="js">
                        <Button onClick={() => handleClose()} variant="contained" ><ArrowLeftIcon/>Return</Button>
                        <Tooltip arrow title="Copied!" open={tooltip} onClose={() => setTooltip(false)} leaveDelay={1000}>
                            <Button onClick={() => handleCopy()} variant="contained" style={{marginTop: '0.5rem'}} ><LinkIcon /> Copy Link</Button>
                        </Tooltip>
                    </Hidden>
                    <Hidden xlUp implementation="js">
                        <Button onClick={() => handleClose()} variant="contained"  style={{marginTop: '0.5rem', marginLeft: '0.5rem'}} ><ArrowLeftIcon/></Button>
                        <Tooltip arrow title="Link Copied!" open={tooltip} onClose={() => setTooltip(false)} leaveDelay={1000}>
                            <Button onClick={() => handleCopy()} variant="contained" style={{marginTop: '0.5rem', marginLeft: '0.5rem'}} ><LinkIcon /></Button>
                        </Tooltip>
                    </Hidden>
                </div>
            </Dialog>
        </Suspense>
    );
}

export default App; 