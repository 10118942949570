import {makeStyles} from '@material-ui/core/styles';

export default makeStyles((theme) => ({
    appBar: {
        borderRadius: 15,
        margin: '30px 0',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      },
      heading: {
        color: 'rgba(0,183,255, 1)',
      },
      image: {
        marginLeft: '15px',
      },
      [theme.breakpoints.down('sm')]: {
        mainContainer: {
          flexDirection: "column-reverse"
        }
      },
    frame: {
      border: '0',
      height: '100%'
    },
    returnButton: {
      position: 'fixed',
      left: '3rem',
      top: '3rem',
      display: 'flex',
      flexDirection: 'column',
    },
    [theme.breakpoints.down('lg')]: {
      returnButton: {
        left: '3rem',
        top: '0.5rem',
        flexDirection: 'row',
      }
    },
    container: {
      display: "flex",
      flexFlow: "column",
      height: "100vh",
    }
      
}));